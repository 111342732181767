import React, {Component} from "react";

import onClickOutside from "react-onclickoutside";

class MenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {open: false};
  }
  render() {
    const {children, label} = this.props;
    return (
      <div className='c-header__menu-dropdown' onClick={this.toggleList} onKeyDown={this.toggleList} role='button' tabIndex={0} aria-label='Menù dei servizi'>
        {label}
        <div className={`c-header__menu-dropdown-list ${this.state.open ? "open" : ""}`}>{children}</div>
      </div>
    );
  }

  toggleList = () => {
    this.setState({open: !this.state.open});
  };

  handleClickOutside = evt => {
    this.setState({open: false});
  };
}

export default onClickOutside(MenuDropdown);
