import React, {Component} from "react";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {activeClass: "normal"};
  }
  componentDidMount() {
    if (typeof window != undefined) {
      window.addEventListener("scroll", () => {
        let activeClass = "c-header_menu--normal";
        if (window.scrollY >= 200) {
          activeClass = "c-header_menu--sticky";
        }
        this.setState({activeClass});
      });
    }
  }
  render() {
    return (
      <div className={`container ${this.state.activeClass}`}>
        <div className='row'>
          <div className='col-12'>
            <div className='c-header__menu'>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Menu;
