import React from "react";
import Menu from "./menu";
import MenuLink from "./menuLink";
import MenuDropdown from "./menuDropdown";
import MenuDropdownLink from "./menuDropdownLink";
import HeaderMainRow from "./headerMainRow";

export const Header = () => (
  <div className='c-header'>
    <HeaderMainRow />
    <Menu>
      <MenuDropdown label={"Servizi e Prodotti"} to={"/contatti"}>
        <MenuDropdownLink to={"/progettazione"}>Progettazione</MenuDropdownLink>
        <MenuDropdownLink to={"/software"}>Software Engineering</MenuDropdownLink>
        <MenuDropdownLink to={"/quadri-elettrici"}>Realizzazione quadri elettrici e impianti</MenuDropdownLink>
        <MenuDropdownLink to={"/retrofitting"}>Retrofitting</MenuDropdownLink>
        <MenuDropdownLink to={"/teleassistenza"}>Teleassistenza da remoto</MenuDropdownLink>
      </MenuDropdown>
      <MenuLink to={"/chi-siamo"}>Chi siamo</MenuLink>
      <MenuLink to={"/contatti"}>Contatti</MenuLink>
    </Menu>
  </div>
);

export default Header;
