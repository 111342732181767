import React, {Component} from "react";

import AniLink from "gatsby-plugin-transition-link/AniLink";

class Footer extends Component {
  render() {
    return (
      <div className='c-footer'>
        <div className='c-footer-main'>
          <div className='container '>
            <div className='row'>
              <div className='col-6 col-md-4 col-lg-3 mt-5 mt-md-0 '>
                <div className='c-footer-column__title logo' />
                <div className='c-footer-column__text mt-3'>
                  Elettronica e automazione industriale: una passione che ci guida da oltre quarant’anni.
                  <br />
                  <a href='http://www.aziendaqualitaitaliana.it' target='_blank' rel='noreferrer' title='Elettronica Scarpa è Azienda di Qualità Italiana' className='d-none d-md-block'>
                    <img src='/image/azq.jpg' alt={"Elettronica Scarpa è Azienda di Qualità Italiana"} className='img-fluid pt-3' />
                  </a>
                </div>
              </div>
              <div className='col-6 col-md-4 offset-lg-3 col-lg-3 mt-5 mt-md-0 '>
                <div className='c-footer-column__title'>ESPLORA IL SITO</div>
                <div className='c-footer-column__link-list mt-3'>
                  <AniLink className='c-header__menu-dropdown-link' paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/chi-siamo"}>
                    L'azienda
                  </AniLink>
                  <AniLink className='c-header__menu-dropdown-link' paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/#servizi"}>
                    I servizi
                  </AniLink>

                  <AniLink className='c-header__menu-dropdown-link' paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/contatti"}>
                    Dove siamo
                  </AniLink>
                  <AniLink className='c-header__menu-dropdown-link' paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/contatti"}>
                    Contatti
                  </AniLink>
                </div>
              </div>
              <div className='col-12 col-md-4 col-lg-3 mt-5 mt-md-0 '>
                <div className='c-footer-column__title'>CONTATTI</div>
                <div className='c-footer-column__icon-content mt-3'>
                  <div className='c-footer-column__icon geo' />
                  <div className='c-footer-column__icon-text'>Via Castellengo 42/A 13836 Cossato (BI) </div>
                </div>
                <div className='c-footer-column__icon-content'>
                  <div className='c-footer-column__icon phone' />
                  <div className='c-footer-column__icon-text'>+39 015 983805 </div>
                </div>
                <div className='c-footer-column__icon-content'>
                  <div className='c-footer-column__icon mail' />
                  <div className='c-footer-column__icon-text'>info@elettronicascarpa.it</div>
                </div>
                <div className='c-footer-column__icon-content'>
                  <div className='c-footer-column__icon time' />
                  <div className='c-footer-column__icon-text'>Lun - Ven: 8-12 / 14-18</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='c-footer-end'>
          <div className='container '>
            <div className='row'>
              <div className='col-12 c-footer-end__row'>
                © 2020 Elettronica Scarpa.{" "}
                <span>
                  <AniLink paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/privacy"}>
                    Privacy Policy
                  </AniLink>
                  |{" "}
                  <AniLink paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/cookie"}>
                    Cookie Policy
                  </AniLink>
                  |{" "}
                  <AniLink paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"INF_AI_CLIENTI-e-FORNITORI-2022_2023-ElettronicaScarpa.pdf"}>
                    Informativa Privacy clienti/fornitori
                  </AniLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
