import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const MenuDropdownLink = ({children, to}) => (
  <AniLink activeClassName='active' className='c-header__menu-dropdown-link' paintDrip hex='#ad151e' duration={0.7} bg='#ad151e' to={to}>
    {children}
  </AniLink>
);

export default MenuDropdownLink;
