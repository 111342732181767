import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Footer from "./footer/footer";
import CookieConsent, {Cookies} from "react-cookie-consent";
import SEO from "./seo";
import "../assets/scss/website.scss";

const Layout = ({children}) => {
  return (
    <>
      <CookieConsent
        location='bottom'
        buttonText='Accetta'
        enableDeclineButton
        declineButtonText='Rifiuto'
        onDecline={() => {
          console.log(document.cookie);
          document.cookie = "gatsby-gdpr-google-analytics=false";
        }}
        onAccept={({acceptedByScrolling}) => {
          console.log(document.cookie);
          document.cookie = "gatsby-gdpr-google-analytics=true";
        }}
        cookieName='gatsby-gdpr-google-analytics'
        style={{background: "#000000"}}
        declineButtonStyle={{background: "#272c2f", color: "#ffffff", fontSize: "12px", margin: "8px"}}
        buttonStyle={{background: "#ad151e", color: "#ffffff", fontSize: "12px", margin: "8px", fontWeight: "bold", textTransform: "uppercase"}}
        expires={150}
        debug={false}
      >
        Questo sito sito utilizza i cookie per offrire una migliore esperienza di navigazione e analizzare il traffico. Maggiori informazioni nella nostra <a href='/cookie'>cookie policy</a>.
      </CookieConsent>
      <SEO></SEO>
      <Helmet></Helmet>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `/favicon/favicon-16x16.png`
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `/favicon/favicon-16x16.png`
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "192x192",
            href: `/favicon/android-chrome-192x192.png`
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "256x256",
            href: `/favicon/android-chrome-256x256.png`
          },
          {
            rel: "manifest",
            href: "/favicon/site.webmanifest"
          }
        ]}
      ></Helmet>
      {children}

      <Footer />
    </>
  );
};

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
