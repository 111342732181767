import React, {Component} from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

class HeaderMainRow extends Component {
  constructor(props) {
    super(props);
    this.state = {activeClass: "normal"};
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    if (typeof window != undefined) {
      window.addEventListener("scroll", () => {
        let activeClass = "c-header--normal";
        if (window.scrollY >= 160) {
          activeClass = "c-header--sticky";
        }
        this._isMounted &&
          this.setState({
            activeClass
          });
      });
    }
  }
  render() {
    return (
      <div className={`${this.state.activeClass}`}>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='c-header-main'>
                <AniLink className='c-header-main__icon' paintDrip hex='#ad151e' duration={0.7} bg='#000000' to={"/"}></AniLink>
                <div className='c-header-main__phone'>
                  <div className='c-header-main__phone-icon' />
                  <div className='c-header-main__phone-container'>
                    <div>CHIAMACI</div>
                    <div>
                      <a href='tel:+39 015983805' title='Chiama Elettronica Scarpa'>
                        +39 015983805
                      </a>{" "}
                      <span className='px-2'>/</span>
                      <a href='tel:+39 0158853355' title='Chiama Elettronica Scarpa'>
                        +39 0158853355
                      </a>
                    </div>
                  </div>
                </div>

                <div className='c-header-main__button'>
                  <a className='e-button' href='#contatti' title="Contatta l'Elettronica Scarpa">
                    Contattaci
                    <div className='e-button__arrow' />
                    <div className='e-button__arrow-background' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderMainRow;
